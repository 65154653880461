import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import daikin from "../assets/images/brands/daikin.jpg";
import lg from "../assets/images/brands/lg.png";
import mitsubishi from "../assets/images/brands/mitsubishi.jpg";
import midea from "../assets/images/brands/midea.jpg";
import Img from "./img";

const SponsorOne = ({ extraClass }) => {
  const logos = [
    { src: daikin, alt: "daikin logo" },
    { src: lg, alt: "lg logo" },
    { src: mitsubishi, alt: "mitsubishi logo" },
    { src: midea, alt: "midea logo" }
  ];

  return (
    <div className={`clients_logo_area text-center section_padding ${extraClass}`}>
      <div className="hero-section-title text-center">
        <h1>Marcas</h1>
      </div>
      <Container>
        <Row>
          {logos.map((logo, index) => (
            <Col key={index} xs={6} sm={4} md={3} className="d-flex align-items-center justify-content-center mb-4">
              <Img className="sponsor-logo" src={logo.src} alt={logo.alt} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default SponsorOne;
